import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { MoneyDisplay } from 'src/ordering/common/components/MoneyDisplay';
import { ProductSummary } from '../../../domain/ProductSummary';
import { venueMenuProductPath } from 'src/app/navigation/pathHelpers';
import { BusinessId } from 'src/business/domain/Business';
import { Typography } from '@mui/material';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { Clear } from '@mui/icons-material';

export type ProductItemProps = {
  menuId: string;
  businessId: BusinessId;
  product: ProductSummary;
  onProductDeleted: (productId: string) => void;
  contextProductList: string[];
  isDeletable: boolean;
};

const ProductContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  border: solid 1px #ebecf1;
  border-radius: 8px;
  padding: 16px;
  gap: 16px;
`;

const ProductInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

interface ProductImageProps {
  imageSrc: string;
}

const ProductImage = styled.div<ProductImageProps>`
  position: relative;
  width: 112px;
  height: 112px;
  flex: 0 0 112px;
  border-radius: 16px;
  background-color: #b6b6b6;
  background-size: cover;
  background-position: center center;
  ${({ imageSrc }) =>
    imageSrc &&
    css`
      background-image: url('${imageSrc}');
    `}
`;

const ProductNoImage = styled.div`
  position: relative;
  min-width: 112px;
  max-width: 112px;
  width: 112px;
  height: 112px;
  border-radius: 16px;
  background-color: #b6b6b6;
`;

const ProductImageContainer = styled.div``;

const IconContainer = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  height: 32px;
  width: 32px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DeleteProductFromCategory: FC<{ onProductDeleted: (productId: string) => void; productId: string }> = ({
  onProductDeleted,
  productId,
}) => (
  <IconContainer
    onClick={(e) => {
      e.stopPropagation();
      onProductDeleted(productId);
    }}
  >
    <Clear sx={{ color: themeV5.palette.secondary.dark }} />
  </IconContainer>
);


const TypoConstrained = styled(Typography)<{ maxLines: number }>`
  overflow: hidden;
  line-clamp: ${(props) => props.maxLines};
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.maxLines};
  -webkit-box-orient: vertical;
  word-break: break-all;
`;


export const ProductItem = ({
  product,
  businessId,
  contextProductList,
  menuId,
  onProductDeleted,
  isDeletable,
}: ProductItemProps) => {
  const navigateTo = useNavigate();

  function onClickProduct() {
    return navigateTo(venueMenuProductPath(businessId, product.id), { state: { contextProductList, menuId } });
  }

  return (
    <ProductContainer onClick={onClickProduct}>
      <ProductInfoBlock>
        <Typography variant={'subtitle1'}
                    fontWeight={500}
                    maxWidth={'180px'}
                    textOverflow={'ellipsis'}
                    overflow={'hidden'}>
          {product.name}
        </Typography>
        <TypoConstrained variant={'body2'} maxLines={2}>
          {product.description}
        </TypoConstrained>
        {product.sku && (
          <TypoConstrained maxLines={2} variant={'body2'} color={themeV5.palette.text.secondary}>
            sku: {product.sku}
          </TypoConstrained>
        )}
        <MoneyDisplay money={product.price} />
      </ProductInfoBlock>
      <ProductImageContainer>
        {product.pictureUrl ? <ProductImage imageSrc={product.pictureUrl} /> : <ProductNoImage />}
      </ProductImageContainer>
      {isDeletable && <DeleteProductFromCategory onProductDeleted={onProductDeleted} productId={product.id} />}
    </ProductContainer>
  );
};
